/* general styles */
.App {
  display: flex;
  flex-direction: column;
  height: 100vh;
  /* 1 */
}

body {
  background-color: #2b69b3;
  color: #ffffff;
}

#content {
  flex: 1 0 auto;
}

button:focus {
  outline: none !important;
}

.pane .form-control {
  border-color: #292929;
}

.pane {
  width: fit-content;
  padding: 2rem;
  margin-left: auto;
  margin-right: auto;
  border-radius: 0.375rem;
}

.modal-content {
  background-color: #212529;
}

/* todo: remove this and use gutter */
.col-12 {
  margin-bottom: 1rem;
}

/* footer */
#footer {
  /* margin-top: 1rem; */
  flex-shrink: 0;
}

/* header */
.navbar-brand {
  font-size: 2rem;
  font-weight: bold;
  font-style: italic;
}

#user-nav {
  color: #5cb85c;

}

#header .nav-link {
  font-size: 1.25rem;
}

#no-user-nav {
  color: #d9534f;
}

#utilities {
  color: #f0ad4e;
}

#about {
  color: #5bc0de;
}

#contact {
  color: #0275d8;
}

.dropdown-item.active {
  color: var(--bs-dropdown-link-color);
  background-color: transparent;
}

.dropdown-item.active:hover {
  background-color: var(--bs-dropdown-link-hover-bg);
}

.link {
  text-underline-offset: 3.5px;
}

/* login */
#login svg {
  width: 24px;
}

.inline-label {
  margin: 0 1rem 0 0;
}

.forgot-password {
  margin-left: 25px;
}

/* search form */
.search-fields .col-12 {
  margin-bottom: 10px;
}

/* search table */
.article-link {
  color: #000000;
  text-decoration: none;
}

.article-link:hover {
  color: #000000;
  text-decoration: underline;
  text-underline-offset: 4px;
}

.MuiTableCell-body {
  cursor: pointer;
}

.dl-msg {
  font-weight: bold;
  text-align: end;
  color: #8877cb;
}

/* source filter */
.source-filter-group hr {
  color: #999;
  margin-top: 0;
  margin-bottom: 0;
}

/* article page */
.card-header {
  border-bottom-color: #000000;
}

.irrelevantArticle {
  color: #d9534f;
  font-weight: bold;
}

.incompleteArticle {
  color: #f0ad4e;
  font-weight: bold;
}

#articleDetails {
  padding-left: 0;
}

#articleContents {
  padding-right: 0;
  padding-left: 0;
}

@media screen and (max-width: 991px) {
  #articleDetails {
    padding-right: 0;
  }
}

#article-title {
  font-style: italic;
}

#article-text {
  white-space: pre-wrap;
  max-height: 75vh;
  overflow-y: scroll;
  padding-right: 1rem;
}

#tabs .nav-link {
  font-style: italic;
  color: rgba(var(--bs-dark-rgb), var(--bs-text-opacity));
}

#tabs .nav-link.active {
  font-weight: bold;
}

.tab-content {
  padding: 0.5rem 1rem;
}

.article-image {
  width: 100%;
}

th.MuiTableCell-head {
  word-wrap: normal;
}

.facebook-table,
.facebook-table .MuiTableCell-sizeMedium {
  background-color: #4267B2 !important;
}

.reddit-table,
.reddit-table .MuiTableCell-sizeMedium {
  background-color: #FF4500 !important;
}

.twitter-table,
.twitter-table .MuiTableCell-sizeMedium {
  background-color: #1DA1F2 !important;
}

.before-pub,
.before-pub .MuiTableCell-sizeMedium {
  background-color: #198754 !important;
}

.after-pub,
.after-pub .MuiTableCell-sizeMedium {
  background-color: #7790cb !important;
}

.smm-table,
.smm-table .MuiTableCell-sizeMedium,
.smm-table div[class*=MUIDataTableHeadCell-sortActive],
.smm-table svg,
.sim-table,
.sim-table .MuiTableCell-sizeMedium,
.sim-table div[class*=MUIDataTableHeadCell-sortActive],
.sim-table svg,
.smm-table .MuiTablePagination-displayedRows,
.sim-table .MuiTablePagination-displayedRows {
  color: #ffffff !important;
}

.text-mbfc,
.text-mbfc:hover {
  color: #c32032;
}

.text-allsides,
.text-allsides:hover {
  color: #2e65a0;
}

.text-mbm,
.text-mbm:hover {
  color: #8877cb;
}

.mbm-carousel .carousel-item {
  height: 600px;
}

.mbm-carousel {
  max-height: 600px;
}

/* profile */
.modal-header>.btn-close {
  background-color: #d9534f;
}

/* terms of use */
.terms a {
  word-wrap: break-word;
}

/* about page */
.scrollTable {
  max-height: 50vh;
  overflow-y: scroll;
}